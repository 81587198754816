<template>
  <div
    class="a-product-volume"
    :class="{ [`a-product-volume--${size}`]: size?.length }"
  >
    <div
      v-for="volume in volumes"
      :key="volume.value + volume.productCode"
      class="a-product-volume__item"
      :class="{
        'active': volume.value === currentVolume,
        'ended': isEnded,
      }"
    >
      {{ volume.value }} {{ volume.valueUnit }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'

import type { ComputedRef, PropType } from 'vue'
import type { Volume } from '@/modules/nuxt-api/models/Product'

type Size = 'sm' | 'xs' | undefined

export default defineComponent({
  name: 'AProductVolume',
  props: {
    isEnded: {
      type: Boolean,
      default: false
    },
    volumes: {
      type: Array as PropType<Volume[]>,
      required: true,
      default: () => []
    },
    size: {
      type: String as PropType<Size>,
      default: undefined
    }
  },
  setup (props) {
    const route = useRoute()

    const { volumes } = toRefs(props)
    const currVolumeIndex: ComputedRef<number> = computed(() => volumes.value?.findIndex((item: Volume) => item.isCurrent))
    const routeVolume = route?.query.volume ?? 0
    const currentVolume = computed(() => currVolumeIndex.value !== -1 ? volumes.value[currVolumeIndex.value].value : routeVolume)

    return { currentVolume }
  }
})
</script>

<style lang="postcss">
.a-product-volume {
  display: flex;
  gap: var(--spacer-3xs);
  color: var(--color-text-dark);
  user-select: none;

  &__item {
    padding: var(--spacer-5xs) var(--spacer-4xs);
    border-radius: var(--border-radius-2xs);
    white-space: nowrap;

    &.active {
      background-color: var(--color-neutral-100);
    }

    &.ended {
      color: var(--color-text-middle);
    }
  }

  &--sm {
    color: var(--color-neutral-700);

    @mixin text-sm;
  }

  &--xs {
    @mixin text-xs;
  }

  @mixin text-sm;
}
</style>
