<template>
  <div class="m-params">
    <div ref="titleElem">
      <p>{{ cuttedTitle }}</p>
      <meta v-if="withMarking" itemprop="name" :content="cuttedTitle">
    </div>
    <div ref="descriptionElem">
      <template
        v-for="(item, idx) in description"
        :key="item.name"
      >
        <template v-if="item.path && !item.isClosed">
          <ALink
            v-if="item.path"
            :href="
              item.path ?
                isExternalLink(item.path) ?
                  item.path :
                  getUrl(item.path) :
                undefined
            "
            underline
            color="dark"
            :prevent="isExternalLink(item.path)"
            :blank="isExternalLink(item.path)"
          >
            <span v-html="DOMPurify.sanitize(item.name)" />
            <meta v-if="withMarking" itemprop="value" :content="DOMPurify.sanitize(item.name)">
            <link
              v-if="withMarking"
              itemprop="url"
              :href="isExternalLink(item.path) ?
                  item.path :
                  getUrl(item.path)
              "
            >
          </ALink>{{ getSeparator(idx) }}
        </template>
        <template v-else>
          <span
            :class="item.path ? 'link-hidden' : ''"
            @click="item.path ? navigatePush(item.path) : null"
            v-html="DOMPurify.sanitize(item.name)"
          />{{ getSeparator(idx) }}
          <meta v-if="withMarking" itemprop="value" :content="DOMPurify.sanitize(item.name)">
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import DOMPurify from 'isomorphic-dompurify'
import { useNavigate } from '@/composables/useNavigate'

import type { Ref, PropType } from 'vue'
import type { UseResizeObserverReturn } from '@vueuse/core'
import type { CharacteristicFormat } from '@/composables/product'

import ALink from '@/components/atoms/Link/ALink.vue'

defineComponent({ name: 'MParams' })

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: Array as PropType<CharacteristicFormat[]>,
    required: true
  },
  withMarking: Boolean
})

const titleElem: Ref<HTMLElement | null> = ref(null)
const descriptionElem: Ref<HTMLElement | null> = ref(null)
const { getUrl, navigatePush } = useNavigate()

const cuttedTitle = ref(props.title)

const calcLineBreak = (): void => {
  const titleWidth = titleElem.value?.offsetWidth ?? 0
  if (titleWidth !== 0 && props.title?.length * 3.5 > titleWidth) {
    cuttedTitle.value = props.title?.slice(0, Math.round(titleWidth / 3.5 - 3))
    cuttedTitle.value += '...'
  } else {
    cuttedTitle.value = props.title
  }

  const titleHeight = titleElem.value?.offsetHeight ?? 0
  const descriptionHeight = descriptionElem.value?.offsetHeight ?? 0
  const descriptionMargin = titleHeight - descriptionHeight
  if (titleHeight > 24 && descriptionMargin > 0) {
    descriptionElem.value?.setAttribute('style', `margin-top: ${descriptionMargin}px`)
  } else {
    descriptionElem.value?.removeAttribute('style')
  }
}

const getSeparator = (idx: number): string => {
  return idx !== props.description.length - 1 ? ', ' : ''
}

const isExternalLink = (path: string): boolean => path.includes('http')

let observer: UseResizeObserverReturn | null = null
onMounted(() => {
  calcLineBreak()

  observer = useResizeObserver(titleElem, () => window.requestAnimationFrame(() => calcLineBreak))
})

onUnmounted(() => observer?.stop())
</script>

<style lang="postcss">
.m-params {
  display: flex;
  gap: var(--spacer-3xs);
  align-items: flex-start;
  width: 100%;

  & > div:first-child {
    display: flex;
    width: 50%;
    min-width: 50%;

    & p {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      color: var(--color-text-middle);

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        min-width: 20px;
        height: 20px;
        margin-left: var(--spacer-4xs);
        border-bottom: 1px dashed var(--color-neutral-400);
      }
    }
  }

  & > div:last-child {
    display: -webkit-box;

    & > * {
      word-break: break-word;
    }
  }

  & .link-hidden {
    cursor: pointer;

    &:not(:hover) {
      color: var(--color-text-dark);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        color: var(--color-blue-800);
      }
    }
  }
}
</style>
